import { Col, Input, Row, Button } from "antd";
import React, { useState, useCallback, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";
import {
  validPassword,
  validEmail,
  isAuthenticated,
} from "../utils/commonFunctions";

import logo from "../images/artificial-intelligence.png";

import "./login.scss";
import routes from "../routes_mapper";

const LoginContainer = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser);

  const { greetingUserText, logoFile } = useMemo(() => {
    let greetingUserText = "User";
    let logoFile = logo;
    return { greetingUserText, logoFile };
  }, [location?.pathname]);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({ email: null, password: null });

  const hasError = useCallback(() => {
    const { email, password } = state;
    let error = {};

    if (!email?.trim()) {
      error.email = "Email cannot be blank";
    } else if (!validEmail(email)) {
      error.email = "Email is not valid";
    }
    if (!password?.trim()) {
      error.password = "Password cannot be blank";
    } else if (!validPassword(password)) {
      error.password = "Password length should be of minimum 4 characters";
    }

    setError(error);

    return !!Object.keys(error)?.length;
  }, [state]);

  const handleEmailSubmit = useCallback(() => {
    if (!hasError()) {
      const { email, password } = state;
      dispatch(loggedInUserActions.loginRequest({ email, password }));
    }
  }, [dispatch, hasError, state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((prestate) => ({
        ...prestate,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  if (isAuthenticated()) {
    const pathname = location.state?.from;

    if (pathname?.pathname) {
      return <Navigate to={pathname?.pathname} replace />;
    } else {
      return (
        <Navigate to={routes.ROOT} state={{ autoNavigate: true }} replace />
      );
    }
  }

  return (
    <div className="loginContainer">
      <div className="logo-container pointer">
        <img
          style={{ height: 80, width: 80 }}
          src={logo}
          alt="AI"
          className="logo"
        />
      </div>

      <div className="container-step-1">
        <Row className="info mt20 mb20">
          Welcome! Please sign in with your account
        </Row>

        <Row className="input-container mb20">
          <label>Email</label>
          <Input
            className="fw"
            placeholder="Enter your email address"
            size="large"
            onChange={handleChange("email")}
            onPressEnter={handleEmailSubmit}
            value={state?.email}
            disabled={loggedInUser.loading}
          />
          <Row className="error">{error?.email}</Row>
        </Row>

        <Row className="input-container mb20">
          <Col span={24}>
            <Row justify="space-between">
              <label>Password</label>
            </Row>
          </Col>
          <Input
            type="password"
            placeholder="Create a strong password"
            size="large"
            onChange={handleChange("password")}
            value={state?.password}
            onPressEnter={handleEmailSubmit}
            disabled={loggedInUser.loading}
          />

          <Row className="error">{error?.password}</Row>
          {/* <Row className="fw" align="middle" justify="space-between">
            <span
              className="extra-content mt10"
              onClick={() => navigate(routes.SIGNUP)}
            >
              Signup
            </span>
            <span
              className="extra-content mt10"
              onClick={() =>
                navigate(routes.FORGOTPASSWORD, { state: { from: location } })
              }
            >
              Forgot Password
            </span>
          </Row> */}
        </Row>

        {/* Buttons Container Start */}
        <Button
          type="primary"
          block
          onClick={handleEmailSubmit}
          loading={loggedInUser.loading}
          size="large"
          className="signup-btn mb20"
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginContainer;
