import axios from "axios";
import axiosRetry from "axios-retry";

import constants from "../utils/constants";

const DefaultConfig = {
  retry: {
    noOfAttempts: 2,
    interval: 1000, // milliseconds
  },
  exception: {
    // Final error message, if there is some error (except network error)
    // For e.g. internal server error etc
    message:
      "There is some problem while executing your operation. Please try again in a while.",
  },
  setRequests: function setRequests() {
    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount = 5) => {
        console.log("retry called");
        return retryCount * 400;
      },
    });

    axios.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
  },
};

export default DefaultConfig;
