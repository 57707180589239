import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Col, Divider, Input, Row, Upload,message } from "antd";
import {
  LeftOutlined,
  SendOutlined,
  UploadOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { showNotification } from "../utils/commonFunctions";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import SetMessageModal from "./SetMessageModal";
import SetPromptModal from "./SetPromptModal";

const AITrainingFromFileUpload = () => {
  const messageRefs = useRef([]);
  const navigate = useNavigate();

  const [state, setState] = useState({
    message: "",
    text: "",
    showDefaultResponse: false
  });
  const [open, setOpen] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [textFromFiles, setTextFromFiles] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [hideChatInput, setHideChatInput] = useState(false)
  const [loadingOne, setLoadingOne] = useState(false)
  const [loadingTwo, setLoadingTwo] = useState(false)

  //console.log("questions",questions)
  const email = localStorage.getItem("email");
  useEffect(() => {
    const userQueries = messages.filter((item) => item.role === 'user');
    const otherResponses = messages.filter((item) => item.role !== 'user');

    setQuestions(userQueries.map((item) => item.content));
    setAnswers(otherResponses.map((item) => item.content));
  }, [messages]);

  const maxLength = Math.min(questions.length, answers.length);

  const handleSendEmail = async() => {
    try {
      const response = await axios.post(AppUrl.SEND_MAIL, {
        email: email,
        messages: [...Array(maxLength)].map((o,i)=>{
          return {
            user:questions[i],
            bot:answers[i]
          }
        }),
      });
      message.success('Successfully mail sent');
       } catch (error) {
        message.error('Failed to send mail');
       }
    }


  useEffect(() => {
    // Your code here
    const email = localStorage.getItem("email");

    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message",res.data?.data?.error_message)
          localStorage.setItem("prompt",res.data?.data?.prompt)
          if(localStorage.getItem("name") !== res.data?.data?.name){
            localStorage.setItem("name", res.data?.data?.name)
          }
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fatch file data");
      });
  }, []);

  
  const saveData = (customMessage, type) => {
    const email = localStorage.getItem("email");
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    let payload = { email: email, text: state.text.trim() }
    if(type === "custom_message" && typeof customMessage === "string"){
      payload.customMessage = customMessage
    }
    if(type === "prompt" && typeof customMessage === "string"){
      payload.prompt = customMessage
    }
    axios
      .post(AppUrl.SAVE_FILE, payload)
      .then((res) => {
        if (res.data.success) {
          setState((preState) => ({
            ...preState,
            loading: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to save file data");
      });
  };

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["text/plain"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .txt file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const fileId = file?._id || file?.uid;
    if (file.type === "text/plain") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        setTextFromFiles((preTextFile) => ({
          ...preTextFile,
          [fileId]: fileContent,
        }));
      };

      reader.readAsText(file);
    }
    setFileList((preState) => [...preState, file]);
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setTextFromFiles((preFile) => {
      delete preFile[fileId];
      return preFile;
    });

    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  useEffect(() => {
    let text = Object.values(textFromFiles).join("\n");
    setState((preState) => ({
      ...preState,
      text,
    }));
  }, [textFromFiles, fileList.length]);

  const onSend = useCallback(() => {
    let message = state.message.trim();
    const text = state.text.trim();
    if (!message) return;
    if (!text) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });

    const payload = {
      question: message,
      // text: text,
      email: localStorage.getItem("email"),
      // customMessage: custom_message
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.FILES}custom`,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        // console.log(resData);

        if (!resData?.text) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = {};
          let content = resData?.text;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();
            console.log("remove message: ");
            return [...prevMessages, newMessage];
          });

          setTimeout(
              () =>
                  messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                    behavior: "smooth",
                  }),
              100
          );
          console.log("message--->", message)
          if(message.toLowerCase() !== "hi" && message.toLowerCase() !== "hello" && message.toLowerCase() !== "hola"){
            setMessages((prevMessages) => {
              return [...prevMessages, {role: "assistant",
                content: "Did your find this information helpful ?",
                loading: false}, {
                type: "acknowledge"
              }];
            });
          }

        }

        setState((prevState) => ({ ...prevState, loading: false}));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );
  const handleDefaultResponseOne = () => {
    setLoadingOne(true);
    setLoadingTwo(false);
    const custom_message = "Great to hear that! If you have any more questions or need further assistance, feel free to reach out.";
    const newMessages = messages;
    newMessages.push({
      role: "assistant",
      content: custom_message,
      loading: false,
    });

    setMessages(newMessages);
  }
  const handleDefaultResponseTwo = () => {
    setLoadingOne(false);
    setLoadingTwo(true);
    const newMessages = messages;
    newMessages.push({
      role: "assistant",
      content: `We apologize for any inconvenience caused. We strive to improve our responses every day to better address your inquiries.`,
      loading: false,
    });
    newMessages.push({
      role: "assistant",
      content: `For more details, reach out to us via email or phone.`,
      loading: false,
    });
    newMessages.push({
      role: "assistant",
      content: `May we have your email address for assistance?`,
      loading: false,
    });
    newMessages.push({
      type:"email"
    });

    setMessages(newMessages);
    setHideChatInput(true)

  }
  const onSendNegativeMail = ()=>{
    setMessages((prevMessages) => {
      const lastMessageIndex = messages.length - 1;

  //  console.log("--------->", messages)
    setTimeout(
        () =>
            messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
              behavior: "smooth",
            }),
        100
    );

      prevMessages.push({
      role: "assistant",
      content: `Thanks for providing us email, Our team will contact you soon.`,
      loading: false,
    });
    return[...prevMessages];
  })
  }

 // console.log("messages",messages)
  const messageBoxRender = () => {
    if(messages.length === 0){
       return <Row
           className="h-full"
           align="middle"
           justify="center"
       >
         <img
             className="h-64 w-full"
             src="/img/empty-ai-chat.svg"
             alt="Writely Bot"
             style={{"width":"300px"}}
         />
       </Row>
    } else {
      return messages?.map?.((message, i) => {
        if (message.type === "acknowledge") {
          return <Row style={{marginLeft: 50}}>
            <Button
                className="m-1 active"
                size="large"
                type={loadingOne ? "default" : "primary"}
                disabled={loadingOne || loadingTwo}
                onClick={handleDefaultResponseOne}
            >Yes 👍</Button>
            <Button
                className="m-1"
                type={loadingTwo ? "default" : "primary"}
                size="large"
                disabled={loadingOne || loadingTwo}
                onClick={handleDefaultResponseTwo}
            >No 👎</Button>
          </Row>
        } else if(message.type === "email") {
          return <Row align="middle" wrap={false} style={{marginLeft: 50}}>
            <Col className="grow">
              <Input
                  className="chat-input"

                  value={state.email}
                  placeholder={"Please provide your email address"}
                  onChange={handleChange("email")}
                  autosize={{minRows: 1, maxRows: 4 }}
                  onPressEnter={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      onSendNegativeMail();
                    }
                  }}
                  disabled={state.loading}
                  size="large"
              />
            </Col>
            <Button
                className="text-2xl btn-color"
                type="text"
                onClick={onSendNegativeMail}
                // disabled={!state.message.trim()}
                // loading={state.loading}
                size="large"
                icon={<SendOutlined className="text-3xl" />}
            />
          </Row>
        } else {
          return (
              <div
                  key={i}
                  ref={(mRef) => (messageRefs.current[i] = mRef)}
              >
                <OpenAiChatItem key={i} message={message}/>
                <Row justify="end" align="">
                  {message.content ===
                      "Sorry we could not find detail about your query. Please contact our support team at help@oben.com | +91 1232123212." && (
                          <Col
                              style={{
                                marginRight: "20px",
                                marginTop: "-110px",
                              }}
                          >
                            <Button>
                              <MailOutlined onClick={handleSendEmail}/>
                            </Button>
                          </Col>
                      )}
                </Row>
              </div>
          )
        }
      })
    }
  }

  return (
    <>
      <GenerateContentContainer containerClassName="open-ai-chat bg-white-cb">
        <Row className="content-container">
          <Row className="fw h-full" justify="space-between">
            <Col
              className="--left-container"
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              xxl={11}
            >
              <div className="center-frame">
                <div className="tab-frame-cb">
                  <div className="tab-inner-frame-cb">
                    {/* <Col className="title-container mb15">
                      <Row align="middle">
                        <LeftOutlined
                          className="text-xl mr-2"
                          onClick={onGoBack}
                        />

                        <Col className="title">
                          Fine-tune ChatGPT via File Upload
                        </Col>
                      </Row>
                    </Col> */}

                    <Col
                      className=""
                      style={{ height: "475px", overflowY: "auto" }}
                    >
                      {/* chat container start */}
                      <Col className="chat-container h-4/6">
                        <Col className="message-list-container">
                          {messageBoxRender()}
                        </Col>
                      </Col>
                      {/* chat container end */}
                    </Col>

                    {!hideChatInput && <Col className="mt20">
                      {/* chat input container end */}
                      <Row align="middle" wrap={false}>
                        <Col className="grow">
                          <Input
                              className="chat-input"
                              value={state.message}
                              placeholder={loadingTwo ? "Please provide your email address" : "Enter your prompt"}
                              onChange={handleChange("message")}
                              autosize={{minRows: 1, maxRows: 4 }}
                            onPressEnter={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                onSend();
                              }
                            }}
                            disabled={state.loading}
                            size="large"
                          />
                        </Col>
                        <Button
                          className="text-2xl btn-color"
                          type="text"
                          onClick={onSend}
                          disabled={!state.message.trim()}
                          loading={state.loading}
                          size="large"
                          icon={<SendOutlined className="text-3xl" />}
                        />
                      </Row>
                      {/* chat input container end */}
                    </Col>}
                  </div>
                </div>
              </div>
            </Col>

            <Col
              xs={0}
              sm={0}
              md={0}
              lg={1}
              xl={1}
              xxl={1}
              className="h-5/6"
              align="center"
            >
              <Divider type="vertical" />
            </Col>

            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} align="center">
              <Divider />
            </Col>

            <Col
              className="--right-container"
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              xxl={11}
            >
              <Row className="mb15" justify={"space-between"}>
                {/* <input type="file" onChange={handleFileUpload} /> */}

                <Upload
                  className="upload-list-inline"
                  showUploadList={true}
                  accept={".txt"}
                  beforeUpload={beforeUpload}
                  listType="picture"
                  multiple={true}
                  fileList={fileList}
                  onRemove={onRemoveImage}
                  customRequest={updateImageUpload}
                  disabled={state.loading}
                >
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                </Upload>
                <Col>
                  <Button
                    className="mr10"
                    type="primary"
                    onClick={() => setOpenPrompt(true)}
                  >
                    Prompts
                  </Button>
                  <Button
                    className="mr10"
                    type="primary"
                    onClick={() => setOpen(true)}
                  >
                    Custom Message
                  </Button>
                  <Button
                    onClick={saveData}
                    disabled={state.loading}
                    icon={<SaveOutlined />}
                  >
                    Save Data
                  </Button>
                </Col>
              </Row>

              <Col className="sticky h-full top-0">
                <Col className="h-5/6 mb-16">
                  <Row className=" h-full">
                    {/* <Input.TextArea
                      className=" input-text-editor grow-1"
                      value={state.text}
                      placeholder="Provide text or knowledge base here to train your  model."
                      onChange={handleChange("text")}
                      //   autosize={{ minRows: 15, maxRows: 19 }}
                      onPressEnter={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          handleChange("text");
                        }
                      }}
                      disabled={state.loading}
                      size="large"
                    /> */}

                    <ReactQuill
                      style={{
                        height: "560px",
                        marginBottom: "50px",
                        width: "100%",
                      }}
                      placeholder={
                        "Provide text or knowledge base here to train your  model."
                      }
                      value={state.text}
                      disabled={state.loading}
                      onChange={handleChange("text")}
                      theme="snow"
                    />
                  </Row>
                </Col>
              </Col>
            </Col>
          </Row>
        </Row>
      </GenerateContentContainer>
      <SetMessageModal open={open} handleModal={setOpen} save={saveData} />
      <SetPromptModal open={openPrompt} handleModal={setOpenPrompt} save={saveData} />
    </>
  );
};

export default React.memo(AITrainingFromFileUpload);
