import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Tabs,
  Image,
  Form,
  Typography,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { showNotification, detectMob } from "../utils/commonFunctions";
import TabPane from "antd/es/tabs/TabPane";
import MarBusiness from "../images/Mar-Business_1-svg.webp";
import Card from "antd/es/card/Card";
import "./htmlDetails.css";

const HTMLDetails = () => {
  const [state, setState] = useState({ url: {}, response: {}, loading: false, keywords: "" });
    const [form] = Form.useForm();
  const [inputCount, setInputCount] = useState([{urlData:""}]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputCount];
    list[index][name] = value;
    setInputCount(list);
  };

  const handleAddMore = () => {
    setInputCount([...inputCount, { urlData: "" }]);
  };

  const handelRemove = (index) => {
    const list = [...inputCount];
    list.splice(index, 1);
    setInputCount(list);
  };

  const onFinish = () => {
    if(!state.keywords){
      showNotification("error", "Please enter your kewords")
    } else if(!inputCount[0].urlData){
        showNotification("error", "Url can not be empty")
    } else {
        setState((prevState) => {
            let tempData = {}
            inputCount.map((o, i)=> {
                tempData[`url_${i + 1}`] = o.urlData;
            });
            prevState["url"] = tempData;
            return { ...prevState };
        });
        onSend()
    }

    // Handle the form submission logic here
  };

  const handleChange = useCallback((name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    }, []);

  const onSend = () => {
    setState((prevState) => {
      prevState["loading"] = true;
      return { ...prevState };
    });
    console.log("------", state)
    axios({
      method: "POST",
      url: `${AppUrl.HTML_DETAIL}`,
      data: {
          url: state.url,
          keywords: state.keywords
      },
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data.data;
        setState((prevState) => {
          prevState["loading"] = false;
          prevState["response"] = resData;
          return { ...prevState };
        });
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", err.error);
        setState((prevState) => {
          prevState["loading"] = false;
          return { ...prevState };
        });
      });
  };
  const data = state.response?.[0];

  const columns = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      width: 550,
    },
    {
      title: 'URL 1',
      dataIndex: 'url_1',
      key: 'url_1',
    },

    {
      title: 'URL 2',
      dataIndex: 'url_2',
      key: 'url_2',
    },
    {
        title: 'URL 3',
        dataIndex: 'url_3',
        key: 'url_3',
      },
  ];

  const { Text } = Typography;
  const RenderCustomTable = () => (
    <container className={"box-outer-cb"}>
      <Row gutter={[8, 16]} className="mb-8">
        <Col xs={24} sm={24} md={10} lg={7} xl={5}>
          <Text strong>Keyword</Text>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={7}>
          <Form.Item noStyle>
            <Input
                placeholder="Enter your keyword"
                onChange={handleChange("keywords")}
                value={state.keywords}
                disabled={state.loading}
            />
          </Form.Item>
        </Col>
      </Row>

      {inputCount.map((item, index) => (
        <div key={item.id} className={`input-cb`}>
          <Row  gutter={[8, 16]} className="mb-4">
            <Col xs={24} sm={24} md={10} lg={7} xl={5}>
              <Text strong>{index === 0 ? "URL" : ""}</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={7}>
              <Input
                type="text"
                name="urlData"
                placeholder={`Enter URL ${index + 1}`}
                value={item.urlData}
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
               {inputCount.length !== 1 && (
                <Button type="danger" onClick={()=>handelRemove(index)} >
                  <DeleteOutlined
                    className="solid-icon"
                    style={{ color: "red", fontSize: "15px" }}
                  />
                </Button>
              )}
            </Col>
          </Row>
          {inputCount.length - 1 === index && inputCount.length < 3 && (
            <Row gutter={[8, 8]} className="mb-4 btn-box">
              <Col xs={24} sm={24} md={10} lg={7} xl={5}></Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={7} className="mb-4">
                <button type="primary" className={`handle-more`} onClick={() => handleAddMore(index)} > <PlusOutlined /> Add URL </button>
              </Col>
            </Row>
          )}

          </div>
      ))}
    </container>
  );

  const getData = () => {
      const rowData = [
          {
              "name": "Content on page (word count)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Exact match keyword",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Keyword density (%)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "H1 (No. of H1)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Keyword in H1 (Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "H2 (No. of H2)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Keyword in H2 (Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "H3 (No. of H3)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Keyword in H3 (Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "# Keyword in Title",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "# Keyword in Description",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Keyword in URL (Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "First keyword format (H1, H2, Bold/Strong, text)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Page Speed (Mobile/Desktop)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Page Load Time",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "FAQ Section",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Microdata implementation Breadcrumb(Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Microdata implementation Financial Product  (Y/N)",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Microdata implementation (Y/N) Blogs Snapped",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          },
          {
              "name": "Microdata implementation (Y/N) FAQ Schema",
              "url_1": "-",
              "url_2": "-",
              "url_3": "-"
          }
      ]
      state.response.map((data, index)=>{
          rowData[0][`url_${index + 1}`] = data.word_count;
          rowData[1][`url_${index + 1}`] = data.exact_match;
          rowData[2][`url_${index + 1}`] = data.density;
          rowData[3][`url_${index + 1}`] = data.no_of_h1;
          rowData[4][`url_${index + 1}`] = (data?.match_h1) ? "Y" : "N";
          rowData[5][`url_${index + 1}`] = data?.no_of_h2;
          rowData[6][`url_${index + 1}`] = (data?.match_h2) ? "Y" : "N";
          rowData[7][`url_${index + 1}`] = data?.no_of_h3;
          rowData[8][`url_${index + 1}`] = (data?.match_h3) ? "Y" : "N";
          rowData[9][`url_${index + 1}`] = (data?.match_title) ? "Y" : "N";
          rowData[10][`url_${index + 1}`] = (data?.match_p) ? "Y" : "N";
          rowData[11][`url_${index + 1}`] = (data?.match_url) ? "Y" : "N";
          rowData[12][`url_${index + 1}`] = data?.keywordStyle ? data.keywordStyle : "-";
          rowData[13][`url_${index + 1}`] = "-";
          rowData[14][`url_${index + 1}`] = data.responseTime;
          rowData[15][`url_${index + 1}`] = data?.faqSection ? "Y" : "N";
          rowData[16][`url_${index + 1}`] = data?.breadCrumb ? "Y" : "N";
          rowData[17][`url_${index + 1}`] = data?.financialProduct ? "Y" : "N";
          rowData[18][`url_${index + 1}`] = data?.blogs ? "Y" : "N";
          rowData[19][`url_${index + 1}`] = data?.faqSchema ? "Y" : "N";
          return null;
      })
      return rowData;
}
  return (
    <GenerateContentContainer containerClassName="open-ai-chat">
        <Card>
        <Form form={form} onFinish={onFinish}>
        {RenderCustomTable()}

        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!state.keywords.trim()}
                  loading={state.loading}
              >
                Generate report
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Card>
      <br />

      <Card>
          {!data && (
              <Row justify="center" align="middle">
                  <Col>
                      <Image
                          width="100%"
                          maxWidth={600}
                          height="auto"
                          src={MarBusiness}
                          alt="MarBusiness"
                          preview={false}
                      />
                  </Col>
              </Row>
          )}


      {data && Object.values(data) && Object.values(data).length > 0 &&  Object.values(data) && (
              <Tabs defaultActiveKey="1" >
                  <TabPane tab="View 1" key="key_1">
                      <Table
                          columns={columns}
                          dataSource={getData()}
                          esponsive={{ xs: true, sm: true, md: false, lg: false }}
                          scroll={{ x: 'md' }}/>
                  </TabPane>
                  <TabPane tab="View 2" key="key_2">
                      <Tabs tabPosition={"left"} defaultActiveKey="index_0">
                          {data && state.response.map((data,index)=>{
                             return (
                                 <TabPane tab={`URL-${index+1}`} key={"index_"+index}>
                                     {
                                         data && Object.values(data) && Object.values(data).length > 0 && (
                                             <>
                                                 <Row key={"12345"} gutter={[16, 16]}>
                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-5">
                                                         <h1>Count: </h1>
                                                         <div>title: {data?.no_of_title}, </div>
                                                         <div>h1: {data?.no_of_h1}, </div>
                                                         <div>h2: {data?.no_of_h2}, </div>
                                                         <div>h3: {data?.no_of_h3}, </div>
                                                         <div>h4: {data?.no_of_h4}, </div>
                                                         {/*<Text>h5: {data?.no_of_h5}, </Text>*/}
                                                         {/*<Text>h6: {data?.no_of_h6}, </Text>*/}
                                                         <div>paragraph: {data?.no_of_p}</div>
                                                     </Col>

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Page Title: </h1>
                                                         {data?.title.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Heading 1: </h1>
                                                         {data?.h1.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Heading 2: </h1>
                                                         {data?.h2.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Heading 3: </h1>
                                                         {data?.h3.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Heading 4: </h1>
                                                         {data?.h4.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <Text strong>H5: </Text>*/}
                                                     {/*    <Text>{data?.h5}</Text>*/}
                                                     {/*</Col>*/}

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <Text strong>H6: </Text>*/}
                                                     {/*    <Text>{data?.h6}</Text>*/}
                                                     {/*</Col>*/}

                                                     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                         <h1>Paragraph: </h1>
                                                         {data?.p.map(o=><div>{o}</div>)}
                                                     </Col>

                                                     {data && data?.breadCrumbsData?.map((o, i)=> {
                                                        return(<Col key={i} xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <h1>Microdata: {i+1}</h1>
                                                            <div>{o}</div>
                                                            </Col>
                                                        )
                                                     })}

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <h1>bread Crumb: </h1>*/}
                                                     {/*    {data?.breadCrumb?.map(o=><div>{o}</div>)}*/}
                                                     {/*</Col>*/}

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <h1>Financial Product: </h1>*/}
                                                     {/*    {data?.financialProduct?.map(o=><div>{o}</div>)}*/}
                                                     {/*</Col>*/}

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <h1>Blogs: </h1>*/}
                                                     {/*    {data?.blogs?.map(o=><div>{o}</div>)}*/}
                                                     {/*</Col>*/}

                                                     {/*<Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                                     {/*    <h1>Faq Schema: </h1>*/}
                                                     {/*    {data?.faqSchema?.map(o=><div>{o}</div>)}*/}
                                                     {/*</Col>*/}
                                                 </Row>
                                             </>
                                         )}
                                 </TabPane>
                             )
                          })}
                      </Tabs>

                  </TabPane>


              </Tabs>
          )
      }

      </Card>
    </GenerateContentContainer>


  );
};
export default HTMLDetails;
