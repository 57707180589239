import { combineReducers } from "redux";

import loggedInUserReducer from "../loggedInUser/duck/loggedInUserReducer";

import constants from "../utils/constants";

const { ACCESS_TOKEN, ACTION_TYPES } = constants;

const appReducer = combineReducers({
  loggedInUser: loggedInUserReducer,
});

const rootReducer = (state, action) => {
  // clearing redux state when user logs out
  if (action.type === ACTION_TYPES.LOGOUT) {
    localStorage.removeItem(ACCESS_TOKEN);
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
