import React, { useMemo } from "react";
import {
    StopOutlined,
    RobotOutlined,
    GlobalOutlined,
    CheckCircleOutlined,
    UsergroupDeleteOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { abbreviateNumber } from "../../utils/commonFunctions";
import '../../scss/cardItem.scss'

const CardItem = (props) => {
  const { dashboardData } = props;

  const cardData = useMemo(() => {
      const totalCount = dashboardData?.total_bots
      const assessed_writers= dashboardData?.total_organisation
      const not_assessed_writers= dashboardData?.total_query
      const active_writers= dashboardData?.inactive_bots
    return [
      {
        name: "Total Bots",
        value: abbreviateNumber(Number(totalCount || 0), 1),
        icon: <RobotOutlined className="f25" />,
      },
      {
        name: "Total Organization",
        value: abbreviateNumber(Number(assessed_writers || 0), 1),
        icon: <GlobalOutlined className="f25" />,
      },
      {
        name: "Queries Answered",
        value:`${abbreviateNumber(Number(not_assessed_writers || 0), 1)}`,
        icon: <CheckCircleOutlined className="f25" />,
      },
      {
        name: "Inactive Bots",
        value: abbreviateNumber(Number(active_writers || 0), 1),
        icon: <StopOutlined className="f25" />,
      }
    ];
  }, [dashboardData]);

  return (
    <div className="project-insights-container mt20 mb20">
      <Row className="items-container item-i-cb fw">
        {cardData?.map((card) => (
            <Col key={card?.name} xs={24} sm={12} md={12} lg={8} xl={6} style={{paddingInline:"12px"}}>
            <div className="card pri-box-shadow ">
              <Row align={"middle"} justify="space-between" wrap={false}>
                <Col>
                  <p>{card?.value}</p>
                  <span
                    style={{ position: "relative" }}
                    className="whitespace-nowrap"
                  >
                    {card?.name}
                  </span>
                  {card?.subName && (
                    <>
                      <br />
                      <span
                        style={{
                          position: "absolute",
                          bottom: -16,
                          fontStyle: "italic",
                        }}
                        className="whitespace-nowrap"
                      >
                        {card?.subName}
                      </span>
                    </>
                  )}
                </Col>
                {card?.icon}
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CardItem;
