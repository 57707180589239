import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { isAuthenticated } from "../utils/commonFunctions";
import routes from "../routes_mapper";

const PrivateRoute = ({ component: Component }) => {
  const location = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
  }

  return <Component />;
};

export default PrivateRoute;
