import React, { memo, useCallback } from "react";
import { Button, Layout, Row } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import "./AppHeader.scss";
import constants from "../utils/constants";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../routes_mapper";
const { ACTION_TYPES } = constants;

const AppHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); 
  const isCustomBotRoute = location.pathname === '/custom-bot';
  const onLogout = useCallback(() => {
    dispatch({ type: ACTION_TYPES.LOGOUT });

    navigate(routes.LOGIN);
  }, [dispatch, navigate]);
  if (isCustomBotRoute) {
    return null;
  }

  const mailId = localStorage.getItem("email").toLocaleLowerCase();
  return (
    <Layout.Header className="appHeader">
      <Row align="middle" justify="space-between" className="heightFull"  wrap={false}>
        <Row align="middle" className="nowrap">
          {/* toggle menu icon start */}
          {React.createElement(
            props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: props.toggleSidebar,
            }
          )}
          {/* toggle menu icon end */}

          <div className="info-contianer">
            {/* <p className="overflow-ellipse">{localStorage.getItem("name") + " (" + localStorage.getItem("email").toLocaleLowerCase() + ")"}</p> */}
            
            <p className="overflow-ellipse">{`Welcome ${localStorage.getItem("name")}`}</p>
            <span className="mailData">{mailId}</span>
          </div>
        </Row>

        <Row
          align="middle"
          justify="space-between"
          style={{ paddingLeft: "auto" }}
          className="pointer mr20 "
          wrap={false}
          // onClick={()=>localStorage.removeItem("email")}
        >
          <img
            className="icon h-10 w-10 mr20"
            src="/img/icons/robot-icon.svg"
            alt="openai"
          />
          <Button type="primary" onClick={onLogout} icon={<PoweroffOutlined />}>
            Logout
          </Button>

          {/* <div className="vertical-divider  mr20"></div> */}
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default memo(AppHeader);
