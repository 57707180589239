import React, { useCallback, useRef, useState } from "react";
import { Button, Card, Col, Input, Row } from "antd";
import { LeftOutlined, SendOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import GenerateContentContainer from "./components/GenerateContentContainer";
import OpenAiChatItem from "./components/OpenAiChatItem";

import AppUrl from "../config/appUrl";
import { hasApiCallError, showNotification } from "../utils/commonFunctions";

import "./OpenAi.scss";

const OpenAiChat = () => {
  const messageRefs = useRef([]);
  const navigate = useNavigate();

  const [state, setState] = useState({
    message: "",
  });
  const [messages, setMessages] = useState([]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const onSend = useCallback(() => {
    const message = state.message.trim();

    if (!message) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    const payload = { messages: [...newMessages] };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: AppUrl.LOGIN,
      data: {
        "email":"sonoo.gupta@ibism.com",
        "password":"12345"
      }
    }).then((loginRes) => {
      const user = loginRes.data;
      const token = user?.data?.token;

      axios({
        method: "POST",
        url: `${AppUrl.OPENAI}/chat`,
        data: payload,
        headers: {
          Authorization:
              "Bearer " + token,
        },
      })
      .then((response) => {
        const resData = response.data;

        if (hasApiCallError(resData?.meta)) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = resData?.data?.content;
          let content = newMessage?.content;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
              () =>
                  messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                    behavior: "smooth",
                  }),
              100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();

            return [...prevMessages, newMessage];
          });
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });

    })
    .catch((err) => {
      console.log("chat err: ", err);
        showNotification("error", "Failed to login in chatgpt");
      });


  }, [messages, state, messageRefs]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  return (
    <>
      <GenerateContentContainer containerClassName="open-ai-chat">
        <Col className=" content-container h-full">
          {/* heading start */}
          <Row className="fw" justify="space-between">
            <Col className="title-container fw  mb15">
              <Row className=" " align="middle" wrap={false}>
                <LeftOutlined className="text-2xl mr-3" onClick={onGoBack} />

                <Row className=" title" align="middle">
                  {/*<img*/}
                  {/*    className="h-10 w-10 mr-3"*/}
                  {/*    src="/img/icons/robot-icon.svg"*/}
                  {/*    alt="openai"*/}
                  {/*    srcset=""*/}
                  {/*/>*/}

                  <span className="ml5">ChatGpt</span>
                </Row>
              </Row>
            </Col>
          </Row>
          {/* heading end */}

          {/* chat container start */}
          <Col className="chat-container">
            <Col className="message-list-container h-full">
              {messages?.map?.((message, i) => (
                <div key={i} ref={(mRef) => (messageRefs.current[i] = mRef)}>
                  <OpenAiChatItem key={i} message={message} />
                </div>
              ))}

              {!messages.length && (
                <Row className="h-full" align="middle" justify="center">
                  <img
                    className="h-64 w-full"
                    src="/img/empty-ai-chat.svg"
                    alt="Writely Bot"
                  />
                </Row>
              )}
            </Col>
          </Col>
          {/* chat container end */}

          {/* chat input container end */}
          <Card className="chat-input-container" hoverable>
            <Row align="middle" wrap={false}>
              <Col className="grow">
                <Input.TextArea
                  className="chat-input"
                  value={state.message}
                  placeholder="Type your question or topic of interest here"
                  onChange={handleChange("message")}
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  onPressEnter={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      onSend();
                    }
                  }}
                  disabled={state.loading}
                  size="large"
                />
              </Col>
              <Button
                className="text-2xl"
                type="text"
                onClick={onSend}
                disabled={!state.message.trim()}
                loading={state.loading}
                size="large"
                icon={<SendOutlined className="text-3xl" />}
              />
            </Row>
          </Card>
          {/* chat input container end */}
        </Col>
      </GenerateContentContainer>
    </>
  );
};

export default React.memo(OpenAiChat);
