import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Layout, Row } from "antd";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";

const AppLayoutContainer = (props) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    true
  );

  const toggleSidebar = useCallback(
    (bool = null) => {
      setIsSidebarCollapsed(!isSidebarCollapsed);
    },
    [isSidebarCollapsed]
  );

  return (
    <Layout className="fh --main-container">
      <AppSidebar
        toggleSidebar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
      />

      <Layout>
        <AppHeader
          collapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />

        <Layout.Content>{props.children}</Layout.Content>
      </Layout>
    </Layout>
  );
};

export default AppLayoutContainer;
