import { notification } from "antd";
import colors from "./colors";
import moment from "moment-timezone";
const colorMap = {};
let lastColorIndex = 0;

export const dashboardProjectColors = [
  "#00AF82",
  "#5C3B97",
  "#F3AF4B",
  "#1A1D9C",
];

export function getAvatarColor(id = "", transparency) {
  if (!id) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  if (!colorMap.hasOwnProperty(id)) {
    colorMap[id] = colors[lastColorIndex];
    lastColorIndex = lastColorIndex + 1;
    lastColorIndex = lastColorIndex % colors.length;
  }
  return transparency ? `${colorMap[id]}${transparency}` : colorMap[id];
}
export const getNameInitials = (name = "") => {
  name = name?.trim?.();
  if (!name) return;

  let initials = name[0] || "";
  const index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }

  return initials.toUpperCase();
};

export const hasApiCallError = (meta = {}) => {
  let isError = false;
  if (meta?.success !== true || meta?.status !== 201) {
    isError = true;
    const message =
      typeof meta?.message === "string"
        ? meta?.message
        : "Something went wrong";
    showNotification("error", message);
  }

  return isError;
};

export const showNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: "bottomLeft",
  });
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") return false;

  const token = localStorage.getItem("email");
  if (token) {
      return true;
  }
  // else {
  //   localStorage.setItem("email", "fit2trip@procezo.com");
  //   localStorage.setItem("name", "Fit 2 Trip");
  //   return true;
  // }

  return false;
};

export const validEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+[\+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return mailformat.test(email);
};

export const validPassword = (password = "") => {
  return password.length >= 4;
};

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const disabledDate = (current) => {
  // Can not select days before today
  return current && current < moment().startOf("day");
};
export const disabledFutureDate = (current) => {
  // Can not select days after today
  return current && current > moment().endOf("day");
};


export const abbreviateNumber = (num = 0, fixed) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "k", "m", "b", "t"][k]; // append power
  return e;
};
