import { NavLink } from "react-router-dom";
import { DesktopOutlined, WechatOutlined, FileTextOutlined, FileImageOutlined, RedditOutlined, QuestionCircleOutlined, DashboardOutlined } from "@ant-design/icons";
import routes from "../routes_mapper";

const getItem = ({ pathname, label, icon, children }) => ({
  key: pathname,
  icon,
  label:
    typeof label === "string" && pathname ? (
      <NavLink to={pathname}>{label}</NavLink>
    ) : (
      label
    ),
  children: children?.map?.(getItem),
});

const useSidebarMenuItems = () => {
  function keyMapper(label, key, icon, pathname, children, type) {
    return {
      key,
      icon,
      children,
      pathname,
      label,
      type,
    };
  } 
  return [
     getItem(keyMapper('Dashboard', '/', <DashboardOutlined style={{fontSize:"20px"}}/>, routes.ROOT)),
    //getItem(keyMapper('Bot Training', '/', <RedditOutlined style={{fontSize:"20px"}}/>, routes.ROOT)),
    // getItem(keyMapper('AITraining', '/training', <DesktopOutlined />, routes.TRAINING)),
    // getItem(keyMapper('Fine-tune ChatGPT', '/chat', <FileTextOutlined />, routes.CHAT)),
    // getItem(keyMapper('ChatGpt', '/chatbot', <WechatOutlined />, routes.FILES)),
    // getItem(keyMapper('Files', 'files', <FileImageOutlined />, routes.FILEUPLOAD)),
    //getItem(keyMapper('Query Log', '/query-details', <QuestionCircleOutlined style={{fontSize:"20px"}}/>, routes.QUERYDETAILS)),
    //getItem(keyMapper('Custom-Bot', '/custom-bot', <DesktopOutlined />, routes.CUSTOMCHATBOT)),
  ]
};

export default useSidebarMenuItems;
