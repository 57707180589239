const routes = {
  ROOT: "/",
  CHAT: "/chat",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOTPASSWORD: "/forgotpassword",
  EMAIL_VERIFY: "/account-activate",
  RESET_PASSWORD: "/reset-password",
  FILES: "/chatbot",
  TRAINING: "/training",
  FILEUPLOAD: "/files",
  HTMLDETAILS: "/html-details",
  QUERYDETAILS:"/query-details",
  CUSTOMCHATBOT: '/custom-bot',
  BotListing: '/listing',
  LaunchBot: '/launch',
  TrainingBot: '/ai-training',
  Test:"/test"
};

Object.freeze(routes);
export default routes;
